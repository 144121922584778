.container {
  --row-padding: 4rem;
}

.homeRow {
  display: flex;
  margin-bottom: 6rem;
}
.homeRow:nth-child(2n) {
  flex-direction: row-reverse;
}

.mainTextSection {
  width: 60%;
  margin-left: var(--row-padding);
  font-family: var(--header-font);
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.textSection {
  min-width: 30%;
  margin-left: var(--row-padding);
}
.teachingSection {
  width: 40%;
}
.homeRow:nth-child(2n) .textSection {
  margin-left: 0;
  margin-right: var(--row-padding);
}

.teachingImages {
  flex-grow: 1;
  margin-top: 6rem;
}
.rowImages {
  flex-grow: 1;
}
.teachingSection .rowImages {
  margin-top: 5rem;
}

.title {
  font-family: var(--header-font);
  font-weight: bolder;
  margin: 0;
  font-size: 3rem;
}
.subtitle {
  font-size: 1.2rem;
  font-family: var(--body-font);
  text-transform: uppercase;
}
.divider {
  width: 1.5rem;
  height: 0.15rem;
  background-color: var(--font-color);
}

.landscapeImage:not(:first-child) {
  margin-top: -40%;
  margin-left: -40%;
  margin-right: 40%;
  position: relative;
  z-index: 0;
}
.portraitImage {
  position: relative;
  width: 60%;
  z-index: 2;
  margin: auto;
}
.portraitImage:not(:first-child) {
  margin-top: -10%;
  margin-left: 30%;
}

.logoSvg {
  margin-left: 3rem;
  width: 15rem;
  fill: var(--dark-theme-color);
}

@media (--sm-viewport) {
  .homeRow,
  .homeRow:nth-child(2n) {
    flex-direction: column;
  }
  .rowImages,
  .teachingImages {
    margin-bottom: 5rem;
  }
  .landscapeImage:not(:first-child) {
    margin-top: -4%;
    margin-left: 0;
    margin-right: 0;
  }
  .mainTextSection,
  .textSection {
    width: 100%;
    margin: 0;
  }
}
