.container {
  display: grid;
  grid-gap: 0.5rem;
  justify-content: center;
  grid-template-columns: auto 1fr auto;
  font-size: 0.8rem;
  padding: 1.5rem var(--margin);
}
.container a {
  text-decoration: underline;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.socialLinkContainer {
  flex: 1;
  display: flex;
  justify-content: center;
}
.socialLink {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all 500ms ease;
  background-color: var(--font-color);
}
.socialLink:hover {
  transition: all 150ms ease;
  background-color: var(--dark-theme-color);
}
.socialSvg {
  width: 1rem;
  height: 1rem;
  fill: var(--lighter-theme-color);
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media (--sm-viewport) {
  .container {
    grid-gap: 1rem;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
  }
  .socialLinkContainer {
    grid-row: 1;
  }
  .left,
  .right {
    align-items: center;
  }
}
