.container {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem var(--margin);
  z-index: 99;
  --mobile-nav-size: 2.5rem;
  --link-transition: all 100ms ease;
  --menu-transition: all 250ms ease;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logoText {
  font-family: var(--header-font);
  font-size: 2rem;
  font-weight: bold;
  margin-right: 1rem;
}

.logoSvg {
  width: 6rem;
  fill: var(--dark-theme-color);
}

.navContainer {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 2rem;
}
.link {
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  transition: var(--link-transition);
}
.link:hover {
  transition: var(--link-transition);
  transform: translateX(0.2rem);
}
.activeLink:hover::before,
.link:hover::before {
  transition: var(--link-transition);
  transform: translateX(-0.2rem);
}
.activeLink {
  position: relative;
}
.activeLink::before,
.link:hover::before {
  content: "";
  position: absolute;
  height: 80%;
  width: 100%;
  bottom: -0.2rem;
  left: -0.5rem;
  z-index: -1;
  background-color: var(--lighter-theme-color);
}

.mobileNavContainer {
  display: none;
}
.mobileNavButton {
  display: none;
  border: none;
  width: var(--mobile-nav-size);
  height: var(--mobile-nav-size);
  background-color: var(--lighter-theme-color);
  position: relative;
  padding: 0 0.6rem;
  cursor: pointer;
}

.hamburgerIcon {
  position: relative;
  flex: none;
  width: 100%;
  height: 2px;
  background-color: var(--font-color);
  transition: var(--menu-transition);
}
.hamburgerIcon:before,
.hamburgerIcon:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: calc(var(--mobile-nav-size) / -6);
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--font-color);
  transition: var(--menu-transition);
}
.hamburgerIcon:after {
  top: calc(var(--mobile-nav-size) / 6);
}

.closeIcon {
  transform: rotate(135deg);
}
.closeIcon:before,
.closeIcon:after {
  top: 0;
  transform: rotate(90deg);
}
.closeIcon:after {
  opacity: 0;
}

@media (--md-viewport) {
  .mobileNavButton {
    display: block;
  }
  .navContainer {
    display: none;
  }
  .mobileNavContainer {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: scale(0);
    transition: var(--menu-transition);
  }
  .mobileNavOpen {
    opacity: 1;
    transform: scale(1);
    transition: var(--menu-transition);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
  }
  .linkContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2rem 0;
  }
}
@media (--xs-viewport) {
  .logoText {
    font-size: 1.5rem;
  }
  .container {
    padding: 1rem;
  }
  .logoSvg {
    width: 4rem;
  }
}
