.container {
  position: relative;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--lighter-theme-color);
}

/* Types of Overlays */
.bannerTitle {
  --offset-y: -0.8rem;
  --offset-x: -0.8rem;
}
.image {
  --offset-y: -2rem;
  --offset-x: -2rem;
}
.heading {
  --offset-y: 0.2rem;
  --offset-x: -0.5rem;
  height: 25%;
}

/* Overlay direction */
.left {
  bottom: var(--offset-y);
  left: var(--offset-x);
}
.right {
  bottom: var(--offset-y);
  right: var(--offset-x);
}
/* Keeps content on top of overlay */
.content {
  position: relative;
}

@media (--sm-viewport) {
  .image {
    --offset-y: -1rem;
    --offset-x: -1rem;
  }
}
