.container {
  display: flex;
  align-items: center;
  transition: all 200ms ease;
}
.container:hover {
  transition: all 200ms ease;
  transform: translate(0.2rem, -0.2rem);
}

.buttonText {
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  z-index: 1;
  position: inline-block;
}
.arrowSvg {
  position: inline-block;
  margin-left: 1rem;
  fill: var(--font-color);
}

.container:hover .buttonText::before {
  transition: all 200ms ease;
  transform: translate(-0.2rem, 0.2rem);
  height: 50%;
  width: 80%;
}
.buttonText::before {
  transition: all 200ms ease;
  content: "";
  position: absolute;
  height: 80%;
  width: 100%;
  bottom: -0.2rem;
  left: -0.5rem;
  z-index: -1;
  background-color: var(--lighter-theme-color);
}
